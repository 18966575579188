import { consoleLog, isSSR } from "./../../../utils/Helpers";

interface WindowDataLayer {
  push: (data: any) => void;
}
interface Wistia {
  consent: (data: boolean) => void;
  [key: string]: any;
}

declare global {
  interface Window {
    dataLayer: WindowDataLayer;
    _wq: Wistia;
    clarity: (data: string) => void;
  }
}

export const setDefaults = () => {
  if (!isSSR) {
    //@ts-ignore
    window._wq = window._wq || [];
    window._wq.push(function (W: any) {
      W.consent(false);
    });
  }
};

export const setConsent = () => {
  if (!isSSR) {
    if (localStorage.getItem("DigitalAECOMConsent") !== null) {
      const Settings = JSON.parse(
        localStorage.getItem("DigitalAECOMConsent") || "{}"
      );
      try {
        function gtag(...args: any[]) {
          window.dataLayer.push(arguments);
        }
        gtag("consent", "update", {
          functionality_storage: Settings.required ? "granted" : "denied",
          analytics_storage: Settings.general ? "granted" : "denied",
          personalization_storage: Settings.content ? "granted" : "denied",
          ad_storage: Settings.content ? "granted" : "denied",
          security_storage: Settings.security ? "granted" : "denied",
        });
      } catch (e) {
        consoleLog("No Gtag", e);
        console.log(e);
      }

      //   setWistia(Settings.content);
      setClarity(Settings.general);
    }
  }
};

export const checkConsent = (consent: any) => {
  if (typeof window !== "undefined") {
    if (localStorage.getItem("DigitalAECOMConsent") !== null) {
      const Settings = JSON.parse(
        localStorage.getItem("DigitalAECOMConsent") || "{}"
      );
      return Settings[consent];
    } else {
      return false;
    }
  }
};

export const setClarity = (consent: any) => {
  if (consent) {
    typeof window !== "undefined" &&
      window.clarity !== undefined &&
      window.clarity("consent");
  } else {
  }
};

// export function deleteCookie(cookie_name: any) {
//   document.cookie.split(";").forEach(function (cookie) {
//     var cookieName = cookie.trim().split("=")[0];
//     // If the prefix of the cookie's name matches the one specified, remove it
//     if (cookieName !== null) {
//       if (cookieName.indexOf(cookie_name) === 0) {
//         document.cookie =
//           cookieName + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
//       }
//     }
//   });
// }
