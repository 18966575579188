import "./src/css/styles.css";
import { isSSR, pushToDataLayer } from "./src/utils/Helpers";
import wrapWithProvider from "./wrap-with-provider";

!isSSR &&
  window.gtag !== undefined &&
  window.gtag("consent", "default", {
    ad_storage: "denied",
    analytics_storage: "denied",
    functionality_storage: "denied",
    personalization_storage: "denied",
    security_storage: "denied",
  });
export const onRouteUpdate = () => {
  setTimeout(() => {
    pushToDataLayer({ event: "aecom_route_change" });
  }, 500);
};

export const wrapRootElement = wrapWithProvider;

