import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "@stores/store";

export interface searchState {
  search: {
    query: string;
    results: any[];
    loading: boolean;
    error: string;
  };
}

const initialState: searchState = {
  search: {
    query: "",
    results: [],
    loading: false,
    error: "",
  },
};

export const searchSlice = createSlice({
  name: "search",
  initialState,
  reducers: {
    setQuery: (state: any, action: any) => {
      state.search.query = action.payload;
    },
    resetSearch: (state: any) => {
      state.search.query = "";
      state.search.results = [];
      state.search.loading = false;
      state.search.error = "";
    },
    setResults: (state: any, action: any) => {
      state.search.results = action.payload;
    },
  },
  extraReducers: (builder: any) => {},
});

// Selectors

export const selectQuery = (state: RootState) => state.search.search.query;
export const selectResults = (state: RootState) => state.search.search.results;
export const selectLoading = (state: RootState) => state.search.search.loading;
export const selectError = (state: RootState) => state.search.search.error;

//  Actions
export const { setQuery, resetSearch, setResults } = searchSlice.actions;

export default searchSlice.reducer;
