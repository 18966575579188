import React from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  selectCurrentlocale,
  selectUiTranslation,
} from "../slices/contactSlice";
import { setManageCookies } from "../slices/consentSlice";

const Footer = () => {
  const uiTranslation = useSelector(selectUiTranslation);
  const currentlocale = useSelector(selectCurrentlocale) || "en";
  const dispatch = useDispatch();

  const ManageCookies = () => {
    dispatch(setManageCookies(true));
  };

  let Year = new Date().getFullYear();

  return (
    <footer className="w-full md:flex flex-row h-full left-0 z-50 !bg-AECOM_StoneGray-bg justify-center items-center gap-y-3 py-3">
      <div className="w-full md:w-2/3 min-h-10 h-auto text-left lg:pl-10 pl-6 md:pl-6 xl:flex md:flex lg:block md:block  flex-row justify-start items-center">
        <div className="flex flex-row justify-start items-center ">
          <div className="text-xs pt-1.5 pr-1">
            {uiTranslation.partof[currentlocale]}
          </div>
          <a
            className="text-black"
            href="https://aecom.com"
            rel="noreferrer"
            aria-label="aecom"
            target="_blank"
          >
            <svg
              className="h-5 mx-1 fill-current"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 81 18"
            >
              <title>AECOM</title>
              <path d="M76.68 17.66l.06-10.14-5.17 10.14h-2.23L67.48 7.52 64.5 17.66h-4.38L65.6.34h3.97l2.05 9.77L76.71.34h3.97L81 17.66h-4.32zM57.23 9.6c.64-2.93-.47-5.21-2.72-5.66-2.48-.5-4.73 1.36-5.41 4.46-.64 2.93.47 5.21 2.72 5.66 2.49.5 4.74-1.36 5.41-4.46M54.32.04c4.71.33 7.63 4.1 7.1 9.14-.54 5.2-4.81 9.06-9.62 8.73-4.72-.33-7.63-4.1-7.1-9.14.55-5.2 4.81-9.07 9.62-8.73M43.15 17.38c-1.18.53-3.05.62-3.91.62-4.98 0-8.32-3.56-8.15-8.62C31.26 4.16 35.45 0 40.52 0c1.83 0 3.3.42 5.05 1.43l-.85 4.15c-1.14-.95-2.42-1.42-3.87-1.42-3.06 0-5.32 2.1-5.42 5.04-.09 2.78 1.75 4.65 4.54 4.65.86 0 2.63-.09 3.81-.62l-.63 4.15zm-14.1-6.37h-10.9l.62-4.03h10.9l-.62 4.03zm2.5-6.64H19.16l.62-4.03h12.39l-.62 4.03zm-1.41 9.26H17.75l-.62 4.03h12.39l.62-4.03zM7.5 10.47l2.56-6.01h.04l1.3 6.01H7.5zm5.44 7.19h4.19L13.07.34H8.29L0 17.66h4.43l1.44-3.38h6.35l.72 3.38z" />
            </svg>
          </a>
        </div>

        <div className="text-xs xl:pl-4 sm:pl-4 md:pl-0 xl:pt-1.5 sm:pt-1.5 pt-2.5 md:pb-0 pb-6">{`© AECOM ${Year}. ${uiTranslation.rights[currentlocale]}`}</div>
      </div>

      <div className="w-5/6 xl:w-4/6 md:pt-1  pt-2 md:h-10 h-auto lg:pl-10 pl-6 md:text-center text-left md:flex flex-row justify-between items-center md:text-sm text-base md:order-first md:order-none md:order-first order-last md:pb-0 pb-6">
        <div className="transition ease-out duration-300 transform hover:opacity-50">
          <a
            href="https://aecom.com/privacy-policy/"
            rel="noreferrer"
            target="_blank"
          >
            {uiTranslation.privacy[currentlocale]}
          </a>
        </div>
        <div className="transition ease-out duration-300 transform hover:opacity-50">
          <a
            href="https://aecom.com/terms-use/"
            rel="noreferrer"
            target="_blank"
          >
            {uiTranslation.terms[currentlocale]}
          </a>
        </div>
        <div className="transition ease-out duration-300 transform hover:opacity-50">
          <a
            href="https://aecom.com/press-releases/"
            rel="noreferrer"
            target="_blank"
          >
            {uiTranslation.press[currentlocale]}
          </a>
        </div>
        <div
          onClick={() => ManageCookies()}
          className="cursor-pointer transition ease-out duration-300 transform hover:opacity-50"
        >
          Manage cookies
        </div>
      </div>

      <div className="w-2/6  md:w-1/6 lg:w-2/3 h-10 mb-4 md:mb-0 md:text-right text-left flex flex-row md:justify-end  items-center justify-start md:pl-6 pl-4 lg:pr-10 pr-6 ">
        <div className="md:w-6 md:h-6 h-10 w-10  md:mx-1 mx-2 cursor-pointer text-black">
          <a
            href="https://twitter.com/aecom"
            rel="noreferrer"
            aria-label="twitter"
            target="_blank"
          >
            <svg
              className="fill-current"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 27.4 27.4"
            >
              <path
                className="st6"
                d="M20.6 11v.4c0 4.6-3.5 9.8-9.8 9.8-2 0-3.8-.6-5.3-1.6h.8c1.6 0 3.1-.6 4.3-1.5-1.5 0-2.8-1-3.2-2.4.2 0 .4.1.7.1.3 0 .6 0 .9-.1-1.6-.3-2.8-1.7-2.8-3.4.5.3 1 .4 1.6.4-.9-.6-1.5-1.7-1.5-2.9 0-.6.2-1.2.5-1.7 1.7 2.1 4.3 3.5 7.1 3.6-.1-.3-.1-.5-.1-.8 0-1.9 1.5-3.5 3.5-3.5 1 0 1.9.4 2.5 1.1.8-.2 1.5-.4 2.2-.8-.3.8-.8 1.5-1.5 1.9.7-.1 1.4-.3 2-.5-.7.8-1.3 1.4-1.9 1.9M13.7 0C6.1 0 0 6.1 0 13.7s6.1 13.7 13.7 13.7 13.7-6.1 13.7-13.7C27.4 6.2 21.2 0 13.7 0"
              />
            </svg>
          </a>
        </div>
        <div className="md:w-6 md:h-6 h-10 w-10 md:mx-1 mx-2  cursor-pointer text-black">
          <a
            href="https://www.linkedin.com/company/aecom/"
            aria-label="Linkedin"
            rel="noreferrer"
            target="_blank"
          >
            <svg
              className="fill-current"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 27.4 27.7"
            >
              <path
                className="st6"
                d="M21.4 20.6c0 .6-.5 1.1-1.1 1.1h-13c-.6 0-1.1-.5-1.1-1.1V7.4c0-.6.5-1.1 1.1-1.1h13c.6 0 1.1.5 1.1 1.1v13.2zM13.7.2C6.1.2 0 6.4 0 14s6.1 13.7 13.7 13.7S27.4 21.6 27.4 14 21.3.2 13.7.2m2.7 11.9c-1.2 0-1.8.7-2.1 1.1v-1H12v7h2.3v-3.9c0-.2 0-.4.1-.6.2-.4.5-.8 1.2-.8.8 0 1.2.6 1.2 1.6v3.7H19v-4c0-2.1-1.1-3.1-2.6-3.1M9.5 8.9c-.8 0-1.3.5-1.3 1.2s.5 1.2 1.3 1.2 1.3-.5 1.3-1.2-.5-1.2-1.3-1.2M8.4 19.2h2.3v-7H8.4v7z"
              />
            </svg>
          </a>
        </div>
        <div className="md:w-6 md:h-6 h-10 w-10 md:mx-1 mx-2 cursor-pointer text-black">
          <a
            href="https://www.facebook.com/AecomTechnologyCorporation/"
            rel="noreferrer"
            aria-label="facebook"
            target="_blank"
          >
            <svg
              className="fill-current"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 27.4 27.4"
            >
              <path
                className="st6"
                d="M17.4 13.7H15v8.5h-3.5v-8.5H9.8v-3h1.7V8.8c0-1.4.7-3.6 3.6-3.6h2.6v2.9h-1.9c-.3 0-.7.2-.7.8v1.8h2.7l-.4 3zM13.7 0C6.1 0 0 6.1 0 13.7s6.1 13.7 13.7 13.7 13.7-6.1 13.7-13.7C27.5 6.1 21.3 0 13.7 0"
              />
            </svg>
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
