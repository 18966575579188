import { configureStore } from "@reduxjs/toolkit";
import contactSlice from "@slices/contactSlice";
import consentSlice from "@slices/consentSlice";
import uiSlice from "@slices/uiSlice";
import searchSlice from "@slices/searchSlice";

export const store = configureStore({
  reducer: {
    ui: uiSlice,
    contact: contactSlice,
    consent: consentSlice,
    search: searchSlice,
  },
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
