import React from "react";
import {
  BlocksRenderer,
  type BlocksContent,
} from "@strapi/blocks-react-renderer";
import Balancer from "react-wrap-balancer";

type Props = {
  content: BlocksContent;
};

const RichTextBlock = ({ content }: Props) => {
  return (
    <BlocksRenderer
      content={content}
      blocks={{
        heading: ({ children, level }) => {
          switch (level) {
            case 1:
              return (
                <h1>
                  <Balancer>{children}</Balancer>
                </h1>
              );
            case 2:
              return (
                <h2>
                  <Balancer>{children}</Balancer>
                </h2>
              );
            case 3:
              return (
                <h3>
                  <Balancer>{children}</Balancer>
                </h3>
              );
            default:
              return <Balancer>{children}</Balancer>;
          }
        },
        link: ({ url, children }) => (
          <a href={url} target="_blank" rel="noopener noreferrer">
            {children}
          </a>
        ),
      }}
      modifiers={{
        bold: ({ children }) => <strong>{children}</strong>,
        italic: ({ children }) => <span className="italic">{children}</span>,
      }}
    />
  );
};

export default RichTextBlock;
