import React, { Suspense, lazy, useEffect } from "react";
import { withPrefix, navigate } from "gatsby";

import Footer from "./footer";
import { useDispatch } from "react-redux";
import { ArticlesQuery } from "../constants";
import withLocation from "../components/hoc/withlocation";
import { isIE } from "react-device-detect";
import {
  NewSetPageData,
  NewSetTracking,
  getLocation,
} from "../slices/contactSlice";

import { consoleLog, isSSR } from "../utils/Helpers";
import { useSiteData } from "@hooks/useSiteData";

import { useIsClient } from "../utils/useIsClient";
import Consent from "../components/ui/consent/Consent";
import ScriptLoader from "@utils/ScriptLoader";
import { Header } from "@components/phase-1/Header/HeaderShad";

type Props = {
  children: React.ReactNode;
  location: {
    pathname: string;
  };
};

const TemplateWrapper = ({ children, location, ...props }: Props) => {
  let pagedata = ArticlesQuery();
  const dispatch = useDispatch();
  const site = useSiteData();
  const isClient = useIsClient();

  const FingerPrintJS = () => {
    if (!isSSR) {
      const FingerPrint = lazy(
        () => import("../components/tracking/Fingerprint")
      );

      return (
        <Suspense fallback={<div>Loading...</div>}>
          <FingerPrint />
        </Suspense>
      );
    }
  };
  useEffect(() => {
    dispatch(getLocation());
    dispatch(
      NewSetTracking({
        data: site.siteInfo,
      })
    );
    dispatch(NewSetPageData(pagedata));
  }, []);

  if (isIE && location.pathname !== withPrefix("/error")) {
    navigate("/error");
    return null;
  }

  return (
    <div className="font-body">
      <Header />
      <ScriptLoader location={location} />
      {children}
      <Consent />
      <Footer />
      {isClient && <FingerPrintJS />}
    </div>
  );
};

export default withLocation(TemplateWrapper);
