import { useStaticQuery, graphql } from "gatsby";

export interface SiteData {
  site: SiteMetadata;
}
export interface SiteMetadata {
  siteMetadata?: MetaItems;
}
export interface MetaItems {
  title?: string;
  description?: string;
  author?: string;
  siteLanguage?: string;
  banner?: string;
  facebook?: string;
  headline?: string;
  siteUrl?: string;
  siteInfo?: {
    campaign?: string;
    market?: string;
    product?: string;
    service?: string;
  };
  ogLanguage?: string;
  titleAlt?: string;
  twitter?: string;
}

export const useSiteData = () => {
  const { site } = useStaticQuery(graphql`
    query SiteQuery {
      site {
        siteMetadata {
          author
          description
          siteLanguage
          title
          banner
          facebook
          headline
          siteUrl
          siteInfo {
            campaign
            market
            product
            service
          }
          ogLanguage
          titleAlt
          twitter
        }
      }
    }
  `);
  // console.log("site", site);
  return site.siteMetadata as MetaItems;
};
