import { useStaticQuery, graphql } from "gatsby";

//
// export const ArticlesQuery = () => {
//  const {articles} = useStaticQuery(graphql`
//    query MyQuery {
//    allStrapiArticle {
//      edges {
//        node {
//          published_at
//          slug
//          locale
//          localizations {
//            locale
//          }
//          Header_image {
//            localFile {
//              childImageSharp {
//                gatsbyImageData
//              }
//            }
//          }
//          title
//          type
//          service_pillars {
//            title
//            slug
//            locale
//            localizations {
//              id
//            }
//          }
//          markets {
//            locale
//            Title
//            slug
//            localizations {
//              id
//            }
//          }
//          article_authors {
//            name
//          }
//        }
//      }
//    }
//  }`
// )

// return articles.MyQuery
// }

export const ArticlesQuery = () => {
  const {
    allStrapiArticle,
    allStrapiWithoutlimit,
    allStrapiContact,
    allStrapiProject,
    allStrapiMarket,
    allStrapiServicePillar,
    allStrapiPage,
    allStrapiService,
    strapiHomepage,
    allStrapiValueProposition,
    allStrapiProduct,
  } = useStaticQuery(graphql`
    query SiteMetaData {
      allStrapiArticle {
        articles: edges {
          node {
            createdAt
            slug
            locale
            featured
            # localizations {
            #   locale
            # }
            Header_image {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    layout: FULL_WIDTH
                    quality: 50
                    sizes: "400"
                    placeholder: DOMINANT_COLOR
                  )
                }
              }
            }
            title
            type
            service_pillars {
              title
              slug
              locale
              # localizations {
              #   id
              # }
            }
            services {
              title
              slug
              locale
              # localizations {
              #   id
              # }
            }
            markets {
              locale
              Title
              slug
              # localizations {
              #   id
              # }
            }
            article_authors {
              name
            }
          }
        }
      }
      allStrapiWithoutlimit {
        WL: edges {
          node {
            contacts {
              name
            }
            service_pillars {
              locale
              # localizations {
              #   id
              # }
              slug
              title
            }
            markets {
              locale
              Title
              slug
              # localizations {
              #   id
              # }
            }
            services {
              title
              slug
              locale
              # localizations {
              #   id
              # }
            }
            createdAt
            slug
            title
            WL_ID
            OG_image {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    layout: FULL_WIDTH
                    quality: 50
                    sizes: "400"
                    placeholder: DOMINANT_COLOR
                  )
                }
              }
            }
          }
        }
      }
      allStrapiProject {
        projects: edges {
          node {
            createdAt
            country {
              Name
            }
            slug
            locale
            # localizations {
            #   locale
            # }
            Project_image {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    layout: FULL_WIDTH
                    quality: 50
                    sizes: "400"
                    placeholder: DOMINANT_COLOR
                  )
                }
              }
            }
            Project_name
            service_pillars {
              title
              slug
              locale
              # localizations {
              #   id
              # }
            }
            services {
              title
              slug
              locale
              # localizations {
              #   id
              # }
            }
            markets {
              locale
              Title
              slug
              # localizations {
              #   id
              # }
            }
            contacts {
              name
            }
          }
        }
      }

      allStrapiMarket {
        markets: edges {
          node {
            Title
            meta_description
            articles {
              title
              slug
              id
              # localizations {
              #   id
              # }
              locale
            }
            slug
            locale
            # localizations {
            #   locale
            # }
            projects {
              locale
              Project_name
              slug
              id
            }
            withoutlimits {
              title
              slug
              id
            }
            value_propositions {
              locale
              title
              slug
            }
          }
        }
      }
      allStrapiServicePillar {
        servicespillars: edges {
          node {
            title
            meta_description
            menu_order
            articles {
              title
              slug
              id
              # localizations {
              #   id
              # }
              locale
            }
            slug
            locale
            # localizations {
            #   locale
            # }
            projects {
              locale
              Project_name
              slug
              id
            }
            withoutlimits {
              title
              slug
              id
            }
            services {
              slug
              meta_description
              title
              locale
            }
          }
        }
      }

      allStrapiPage {
        pages: edges {
          node {
            locale
            meta_description
            title
            slug
            # localizations {
            #   locale
            # }
          }
        }
      }

      allStrapiService {
        services: edges {
          node {
            locale
            # localizations {
            #   locale
            # }
            slug
            title
            service_pillars {
              locale
              # localizations {
              #   id
              # }
              meta_description
              slug
              title
            }
          }
        }
      }

      strapiHomepage {
        About_pages {
          slug
        }
      }

      allStrapiValueProposition {
        value: edges {
          node {
            locale
            # localizations {
            #   locale
            # }
            markets {
              Title
              locale
              slug
            }
            title
            slug
          }
        }
      }
      allStrapiContact {
        Contacts: edges {
          node {
            createdAt
            publishedAt
            slug
            position
            email
            image {
              localFile {
                publicURL
                childImageSharp {
                  gatsbyImageData(
                    layout: FULL_WIDTH
                    quality: 40
                    sizes: "400"
                    placeholder: BLURRED
                  )
                }
              }
            }
            name
          }
        }
      }
      allStrapiProduct {
        products: edges {
          node {
            locale
            # localizations {
            #   locale
            # }
            beta
            menu_text
            meta_description
            slug
            title
          }
        }
      }
    }
  `);

  return {
    allStrapiArticle,
    allStrapiContact,
    allStrapiWithoutlimit,
    allStrapiProject,
    allStrapiMarket,
    allStrapiServicePillar,
    allStrapiPage,
    allStrapiService,
    strapiHomepage,
    allStrapiValueProposition,
    allStrapiProduct,
  };
};
