import React from "react";
import { Provider } from "react-redux";
import { store as newStore } from "./src/stores/store";
import { Provider as WrapProvider } from "react-wrap-balancer";
import { QueryParamProvider } from "use-query-params";
import { GatsbyAdapter } from "./gatsbyadaptor";
import { parse, stringify } from 'query-string';
type Props = {
  element?: React.ReactNode;
  location: {
    pathname: string;
  };
};
export default ({ element }: Props) => {
  return (
    <QueryParamProvider
      adapter={GatsbyAdapter}
      options={{
        enableBatching: true,
        searchStringToObject: parse,
        objectToSearchString: stringify,
      }}
    >
      <WrapProvider>
        <Provider store={newStore}>{element}</Provider>
      </WrapProvider>
    </QueryParamProvider>
  );
};
