import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../stores/store";

export interface uiState {
  AccordionOpen: string;
  MobileMenuOpen?: boolean;
}

const initialState: uiState = {
  AccordionOpen: "",
  MobileMenuOpen: false,
};

export const uiSlice = createSlice({
  name: "ui",
  initialState,
  reducers: {
    setMobileAccordion: (state, action) => {
      state.AccordionOpen = action.payload;
    },
    setMobileMenu: (state, action) => {
      state.MobileMenuOpen = action.payload;
    },
  },
});

// Selectors

export const selectMobileAccordion = (state: RootState) =>
  state.ui.AccordionOpen;

export const selectMobileMenu = (state: RootState) => state.ui.MobileMenuOpen;

//  Actions
export const { setMobileAccordion, setMobileMenu } = uiSlice.actions;

export default uiSlice.reducer;
