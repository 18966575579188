import { motion } from "framer-motion";
import { twMerge } from "tailwind-merge";
import { ThemeTypes } from "../Theme/ThemeTypes";
import { Link } from "gatsby";
import { useDispatch } from "react-redux";
import { setMobileMenu } from "@slices/uiSlice";
import { consoleLog, isSSR } from "@utils/Helpers";

export type ButtonProps = {
  buttonText?: string;
  buttonLink?: string;
  buttonBgColor?: ThemeTypes;
  buttonTextColor?: ThemeTypes;
  className?: string;
  uppercase?: boolean;
  type?: "filled" | "outlined" | "text";
  menuButton?: boolean;
  onClick?: () => void;
};

const Button = ({
  buttonText = "Learn More",
  buttonLink = "/",
  buttonBgColor = "AECOM_EarthGreen",
  buttonTextColor = "white",
  uppercase = false,
  type = "filled",
  menuButton = false,
  //   variants,
  onClick,
  className,
}: ButtonProps) => {
  const dispatch = useDispatch();

  consoleLog("Button", {
    buttonText,
    buttonLink,
    buttonBgColor,
    buttonTextColor,
    uppercase,
    type,
    menuButton,
    onClick,
    className,
  });

  const WhichLink = ({ link, children }: { link: string; children: any }) => {
    if (link.includes("#")) {
      return (
        <a
          onClick={() => {
            // remove / from the buttonLink
            let href = link.replace("/", "");
            const target = document.querySelector(href);

            if (target) {
              target.scrollIntoView({ behavior: "smooth", block: "start" });
            }
            if (menuButton) {
              dispatch(setMobileMenu(false));
            }
          }}
        >
          {children}
        </a>
      );
    } else {
      return (
        <Link
          to={link}
          target={link.includes("http") ? "_blank" : "_self"}
          onClick={() => {
            if (menuButton) {
              dispatch(setMobileMenu(false));
            }
          }}
        >
          {children}
        </Link>
      );
    }
  };

  return (
    <WhichLink link={buttonLink}>
      <motion.button
        onClick={onClick}
        whileHover={{ scale: 1.1 }}
        whileTap={{ scale: 0.9 }}
        className={twMerge(
          `text-${buttonTextColor} leading-snug font-semibold text-xs px-10 py-3 rounded-full`,
          uppercase && "uppercase",
          type === "filled" && `bg-${buttonBgColor}`,
          type === "outlined" &&
            `border-2 border-${buttonBgColor} text-${buttonBgColor}`,
          type === "text" && `text-${buttonBgColor}`,
          className
        )}
      >
        {buttonText}
      </motion.button>
    </WhichLink>
  );
};

export default Button;
