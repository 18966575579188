import { Script } from "gatsby";
import { consoleLog, isSSR } from "./Helpers";

const developement = process.env.NODE_ENV === "development";

type Props = {
  location: any;
};

const ScriptLoader = ({ location }: Props) => {
  consoleLog("ScriptLoader location", location);
  function gtag() {
    !isSSR && window.dataLayer.push(arguments);
  }
  return (
    <>
      <Script
        src={`https://www.googletagmanager.com/gtm.js?id=${
          developement ? "" : "GTM-5X3X2TS"
        }`}
        onLoad={() => {
          // @ts-ignore
          gtag("consent", "default", {
            ad_storage: "denied",
            analytics_storage: "denied",
            functionality_storage: "denied",
            personalization_storage: "denied",
            security_storage: "denied",
          });
        }}
        strategy="post-hydrate"
      />
      <Script id="gtm-init" strategy="post-hydrate">
        {`
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({ 'gtm.start': new Date().getTime(), 'event': 'gtm.js' })
    `}
      </Script>
      <Script id="typekit-css" strategy="idle">
        {`
      (function(d) {
        var config = {
          kitId: 'osl3gir',
          scriptTimeout: 3000,
          async: true
        },
        h=d.documentElement,t=setTimeout(function(){h.className=h.className.replace(/\bwf-loading\b/g,"")+" wf-inactive";},config.scriptTimeout),tk=d.createElement("script"),f=false,s=d.getElementsByTagName("script")[0],a;h.className+=" wf-loading";tk.src='https://use.typekit.net/'+config.kitId+'.js';tk.async=true;tk.onload=tk.onreadystatechange=function(){a=this.readyState;if(f||a&&a!="complete"&&a!="loaded")return;f=true;clearTimeout(t);try{Typekit.load(config)}catch(e){}};s.parentNode.insertBefore(tk,s)
      })(document);

      `}
      </Script>
    </>
  );
};

export default ScriptLoader;
