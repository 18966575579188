import { checkConsent } from "./../components/ui/consent/ConsentHelpers";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export interface consentState {
  ManageCookies: {
    open: boolean;
  };
}

const initialState: consentState = {
  ManageCookies: { open: false },
};

export const consentSlice = createSlice({
  name: "consent",
  initialState,
  reducers: {
    setManageCookies: (state, action) => {
      state.ManageCookies.open = action.payload;
    },
  },
  extraReducers: (builder: any) => {
    builder.addCase(
      setCustomConsent.fulfilled,
      (state: any, action: any) => {},
    );
    builder.addCase(setConsent.fulfilled, (state: any, action: any) => {
      state.ManageCookies.open = action.payload;
    });
  },
});

// Async Thunks

export const setCustomConsent = createAsyncThunk(
  "contact/setCustomConsent",
  async (_, thunkAPI: any) => {
    if (typeof window !== "undefined") {
      if (
        thunkAPI.getState().contact.geo !== null &&
        localStorage.getItem("DigitalAECOM") === null
      ) {
        checkConsent("general") &&
          localStorage.setItem(
            "DigitalAECOM",
            JSON.stringify(thunkAPI.getState().contact.geo),
          );
      }
    }
  },
) as any;

export const setConsent = createAsyncThunk(
  "contact/setConsent",
  async (_, { dispatch, getState }: any) => {
    if (typeof window !== "undefined") {
      if (localStorage.getItem("DigitalAECOMConsent") !== null) {
        const Settings = JSON.parse(
          localStorage.getItem("DigitalAECOMConsent") || "{}",
        );
        try {
          function gtag() {
            // @ts-ignore
            window.dataLayer.push(arguments);
          } // @ts-ignore
          gtag("consent", "default", {
            analytics_storage: Settings.general ? "granted" : "denied",
            personalization_storage: Settings.content ? "granted" : "denied",
            ad_storage: Settings.content ? "granted" : "denied",
            security_storage: Settings.security ? "granted" : "denied",
          });
        } catch (error) {
          console.log(error);
        }
      } else {
        localStorage.setItem(
          "DigitalAECOMConsent",
          JSON.stringify({
            required: true,
            general: true,
            content: true,
            security: true,
          }),
        );

        function gtag() {
          // @ts-ignore
          window.dataLayer.push(arguments);
        } // @ts-ignore
        gtag("consent", "default", {
          analytics_storage: "granted",
          personalization_storage: "granted",
          ad_storage: "granted",
          security_storage: "granted",
        });
        return false;
      }
    }
  },
) as any;

// Selectors

export const selectManageCookies = (state: any) => state.consent.ManageCookies;

//  Actions
export const { setManageCookies } = consentSlice.actions;

export default consentSlice.reducer;
