import React from "react";

const Logo = () => {
  return (
    <div className="h-10 w-2/3 text-left mr-auto lg:pl-18 ">
      <svg
        height="100%"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1872 504"
      >
        <title>Digital AECOM</title>
        <path
          className="text-white fill-current"
          d="M811 211.2a82.2 82.2 0 1 0 0 126v19.2h29.4V192H811ZM758.3 327a52.9 52.9 0 1 1 52.9-52.9 53 53 0 0 1-53 52.9ZM215.4 211.4a82.2 82.2 0 1 0 0 126v19.2h29.4V133.4h-29.4Zm-52.8 115.8a52.9 52.9 0 1 1 52.8-52.9 53 53 0 0 1-52.8 53ZM491.7 192.1h-29.3v19.5a82.2 82.2 0 1 0 0 126.2v11a52 52 0 0 1-103.7 7.6h-29.5a81.5 81.5 0 0 0 162.5-7.5v-69.1a86 86 0 0 0 0-10.2Zm-29.3 86.7a52.8 52.8 0 1 1 0-8.2ZM277.5 192.1h29.4v164.5h-29.4zM872.7 133.1h29.4v223.2h-29.4zM646.3 222l21.5-29.4h-53.3v-59.3h-29.3V274a82.8 82.8 0 0 0 82.6 82.7v-29.4a53.4 53.4 0 0 1-53.3-53.3v-52ZM523.9 192.5h29.4V357h-29.4zM1138.8 191.5h-3v165.4h122.1v-29.5h-92.5V289h80.4v-29.5h-80.4v-38.4h92.5v-29.6h-119.1zM1360.9 218.1a56 56 0 0 1 37.4 14.3l19.7-22a85.6 85.6 0 1 0 0 127.6l-19.7-22a56.1 56.1 0 1 1-37.4-98ZM1512 188.6a85.6 85.6 0 1 0 85.6 85.6 85.7 85.7 0 0 0-85.7-85.6Zm0 141.7a56.1 56.1 0 1 1 56-56 56.2 56.2 0 0 1-56 56ZM1759.1 191.6l-53.1 79.6-53.1-79.6h-32.5v165.3h29.5V240.3l56.1 84.1 56.1-84.1v116.6h29.6V191.6h-32.6zM1032.3 192h-16.5l-69.9 164.5h31.7l18.6-44h72.1l18.6 44h31.7l-69.8-164.6Zm-23.5 90.7 23.5-55.4 23.4 55.4Z"
        />
      </svg>
    </div>
  );
};

export default Logo;
