import * as React from "react";

import { cn, consoleLog } from "@utils/Helpers";
import { navigate } from "gatsby";
import {
  NavigationMenu,
  NavigationMenuContent,
  NavigationMenuItem,
  NavigationMenuLink,
  NavigationMenuList,
  NavigationMenuMobileTrigger,
  NavigationMenuTrigger,
} from "@components/phase-1/Header/Nagivation";

import { Link as GatsbyLink } from "gatsby";
import { useHeaderData } from "@hooks/useHeaderData";
import { useDispatch, useSelector } from "react-redux";
import {
  selectMobileMenu,
  setMobileAccordion,
  setMobileMenu,
} from "@slices/uiSlice";
import { ThemeTypes } from "../Theme/ThemeTypes";
import searchIcon from "@images/icons/menu/search.svg";
import InternalButton from "../Button/Button";
import { Link } from "gatsby";
import RichTextBlock from "../RichTextBlock/RichTextBlock";
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image";
import { AnimatePresence, motion } from "framer-motion";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";
import Logo from "@/components/logo.js";
type Props = {
  location?: any;
  menuItems?: any;
  backgroundColor?: ThemeTypes;
  textColor?: ThemeTypes;
  borderColor?: ThemeTypes;
  borderTop?: boolean;
  buttonText?: string;
  buttonLink?: string;
  buttonBgColor?: ThemeTypes;
  buttonTextColor?: ThemeTypes;
  buttonUppercase?: boolean;
};
export function Header({
  backgroundColor = "black",
  borderColor = "white",
  borderTop = false,
  textColor = "white",
  buttonText = "Get in touch",
  buttonLink = "/contact",
  buttonTextColor = "white",
  buttonUppercase = false,
  buttonBgColor = "AECOM_EarthGreen",
}: Props) {
  const menuItemsData = useHeaderData() as any;

  const ImageVariants = {
    hidden: {
      y: 50,
      scale: 0.95,
      clipPath: "polygon(20% 0%,80% 0%,80% 0%,20% 0%)",
    },
    visible: {
      scale: 1,
      y: 0,
      clipPath: "polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)",
    },
  };

  consoleLog("menuItemsData", menuItemsData);

  const dispatch = useDispatch();

  const mobileMenuOpen = useSelector(selectMobileMenu);
  const [background, setBackground] = React.useState<IGatsbyImageData | null>(
    null
  );

  const handleMobileMenuOpen = () => {
    dispatch(setMobileMenu(!mobileMenuOpen));

    dispatch(setMobileAccordion(""));
  };
  const [text, setText] = React.useState(null);
  const variants = {
    open: {
      transition: { staggerChildren: 0.07, delayChildren: 0.3 },
    },
    closed: {
      transition: { staggerChildren: 0.05, staggerDirection: -1 },
    },
  };

  React.useEffect(() => {
    dispatch(setMobileMenu(false));
  }, []);

  const subvariants = {
    open: {
      y: 0,
      opacity: 1,
      transition: {
        y: { stiffness: 1000, velocity: -100 },
      },
    },
    closed: {
      y: 50,
      opacity: 0,
      transition: {
        y: { stiffness: 1000 },
      },
    },
  };
  return (
    <NavigationMenu
      className={`w-full fixed h-14 top-0 left-0 z-50 bg-${backgroundColor} px-5 text-${textColor}`}
    >
      <div className="hidden text-white text-black text-AECOM_MidnightTeal text-AECOM_EarthGreen text-white text-AECOM_OceanTeal text-AECOM_SunriseYellow text-AECOM_FuchsiaPink text-AECOM_StoneGray border-white border-black border-AECOM_MidnightTeal border-AECOM_EarthGreen border-white border-AECOM_OceanTeal border-AECOM_SunriseYellow border-AECOM_FuchsiaPink border-AECOM_StoneGray bg-white bg-black bg-AECOM_MidnightTeal bg-AECOM_EarthGreen bg-white bg-AECOM_OceanTeal bg-AECOM_SunriseYellow bg-AECOM_FuchsiaPink bg-AECOM_StoneGray border-t-white border-t-black border-t-AECOM_MidnightTeal border-t-AECOM_EarthGreen border-t-white border-t-AECOM_OceanTeal border-t-AECOM_SunriseYellow border-t-AECOM_FuchsiaPink border-t-AECOM_StoneGray" />

      <NavigationMenuList
        // header
        className={`flex w-full text-${textColor} justify-between items-center h-full bg-${backgroundColor} `}
      >
        {/* Mobile Menu */}
        <NavigationMenuItem className="lg:hidden flex justify-center items-center">
          <motion.div
            className="z-50"
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
            data-testid="open-menu"
            onClick={handleMobileMenuOpen}
          >
            <svg
              className="fill-current text-white h-8 md:h-10 transition duration-300 group-data-[state=open]:rotate-180"
              focusable="false"
              aria-hidden="true"
              viewBox="0 0 24 24"
              data-testid="MenuIcon"
            >
              <path
                className="group-data-[state=open]:hidden"
                d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z"
              ></path>
              <path
                className="hidden group-data-[state=open]:block"
                d="M19 6.41 17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
              ></path>
            </svg>
          </motion.div>
          {mobileMenuOpen && (
            <NavigationMenuContent
              forceMount
              className={`bg-${backgroundColor} w-full h-screen flex flex-col justify-start items-start p-5 fixed top-0 left-0 z-40 pt-16 pointer-events-none`}
            >
              <Accordion
                type="single"
                collapsible
                className="w-full pointer-events-auto"
              >
                {menuItemsData.map((item: any, index: number) => {
                  return (
                    <AccordionItem value={item.title} key={index}>
                      <AccordionTrigger className="text-base decoration-transparent w-full pr-3">
                        {item.title}
                      </AccordionTrigger>
                      <AccordionContent>
                        <ul className="flex flex-col gap-5">
                          {item.MenuItem.map((subItem: any, index: number) => {
                            return (
                              <li key={index}>
                                <Link
                                  to={subItem.slug}
                                  onClick={() => {
                                    dispatch(setMobileMenu(false));
                                  }}
                                >
                                  {subItem.title}
                                </Link>
                              </li>
                            );
                          })}
                        </ul>
                      </AccordionContent>
                    </AccordionItem>
                  );
                })}
              </Accordion>
              <Link
                to="/search"
                className="pointer-events-auto"
                onClick={() => {
                  dispatch(setMobileMenu(false));
                }}
              >
                <div className="flex flex-row items-center justify-center gap-3 mt-5">
                  <img src={searchIcon} alt="Search" className="size-5" />
                  {/*  @ts-ignore */}
                  <h4 className="text-base decoration-transparent w-full pr-3">
                    Search
                  </h4>
                </div>
              </Link>
              <div className="inline-block w-full my-5">
                <InternalButton
                  buttonText={buttonText}
                  buttonBgColor={buttonBgColor}
                  buttonTextColor={buttonTextColor}
                  buttonLink={buttonLink}
                  uppercase={buttonUppercase}
                  className="py-3 px-auto text-base font-thin shadow-md hover:shadow-lg !w-full my-3"
                  menuButton={true}
                />
              </div>
            </NavigationMenuContent>
          )}
        </NavigationMenuItem>
        <div className="h-14 flex items-center z-50 flex-1 pointer-events-auto">
          <GatsbyLink to="/">
            <Logo />
          </GatsbyLink>
        </div>
        <div
          className="text-right lg:flex hidden flex-row w-full h-full justify-center items-center pointer-events-auto"
          data-testid="desktop-menu-items-right"
        >
          <div className="flex justify-center items-center w-full h-full pointer-events-auto">
            {menuItemsData.map((item: any, index: number) => {
              return (
                <NavigationMenuItem key={index} className="flex">
                  <NavigationMenuTrigger>{item.title}</NavigationMenuTrigger>
                  <NavigationMenuContent
                    className={`bg-${backgroundColor} w-full h-full flex justify-center items-center min-h-[350px] max-h-[350px]`}
                  >
                    <div className="h-full w-1/3 bg-black flex justify-between items-stretch flex-col overflow-hidden">
                      <NavigationMenuLink>
                        <Link
                          className="flex h-full w-full select-none flex-col justify-center rounded-md bg-gradient-to-b from-muted/50 to-muted p-6 no-underline outline-none focus:shadow-md flex-1"
                          to={item.slug}
                          onClick={() => {
                            dispatch(setMobileMenu(false));
                          }}
                        >
                          <div className="mb-auto">
                            <div className="mb-2 mt-4 text-lg font-medium">
                              {item.title}
                            </div>
                            <div className="text-sm leading-tight text-muted-foreground w-full flex-1">
                              <RichTextBlock content={item.description} />
                            </div>
                          </div>
                        </Link>
                      </NavigationMenuLink>
                      {item.button && (
                        <Link
                          className="flex h-full w-full select-none flex-col justify-end rounded-md bg-gradient-to-b from-muted/50 to-muted p-6 no-underline outline-none focus:shadow-md"
                          to={item.slug}
                          onClick={() => {
                            dispatch(setMobileMenu(false));
                          }}
                        >
                          <div className="mt-4 flex-1">
                            <InternalButton
                              buttonText={item.button.buttonText}
                              buttonBgColor={item.button.buttonBgColor}
                              type={item.button.type}
                              buttonTextColor={item.button.buttonTextColor}
                              buttonLink={item.button.buttonLink}
                              uppercase={item.button.uppercase}
                              className="py-1 px-4 text-sm font-thin shadow-md hover:shadow-lg"
                            />
                          </div>
                        </Link>
                      )}
                      {item.slug?.includes("project_insight") && (
                        <Link
                          className="flex h-full w-full select-none flex-col justify-end rounded-md bg-gradient-to-b from-muted/50 to-muted p-6 no-underline outline-none focus:shadow-md"
                          to={"/search"}
                          onClick={() => {
                            dispatch(setMobileMenu(false));
                          }}
                        >
                          <div className="mt-4 flex-1">
                            <InternalButton
                              buttonText={"Search Projects & Insights"}
                              buttonBgColor="white"
                              type="outlined"
                              buttonTextColor="white"
                              buttonLink={"/search"}
                              uppercase={false}
                              className="py-1 px-4 text-sm font-thin shadow-md hover:shadow-lg"
                            />
                          </div>
                        </Link>
                      )}
                    </div>
                    <motion.div className="w-full h-full flex justify-start items-start relative p-10 overflow-hidden">
                      <motion.div
                        className="w-1/3 h-full flex justify-start items-start flex-col relative"
                        initial="closed"
                        animate="open"
                        variants={variants}
                      >
                        {item.MenuItem.map((subItem: any, index: number) => {
                          return (
                            <motion.div variants={subvariants} key={index}>
                              <NavigationMenuItem key={index} asChild>
                                <ul className="flex flex-col z-10">
                                  <ListItem
                                    href={subItem.slug}
                                    title={subItem.title}
                                    onMouseEnter={() => {
                                      if (
                                        subItem?.background_image?.localFile
                                      ) {
                                        setBackground(
                                          subItem.background_image.localFile
                                            .childImageSharp.gatsbyImageData
                                        );
                                      }
                                      if (subItem?.description) {
                                        setText(subItem.description);
                                      }
                                    }}
                                    onMouseLeave={() => {
                                      setBackground(null);
                                      setText(null);
                                    }}
                                    className="group-[item]:hover:bg-accent/50"
                                  ></ListItem>
                                </ul>
                              </NavigationMenuItem>
                            </motion.div>
                          );
                        })}
                      </motion.div>
                      <div className="w-1/3 h-full flex justify-start items-start flex-col relative">
                        {text && (
                          <div className="leading-tight w-full prose prose-invert">
                            <RichTextBlock content={text} />
                          </div>
                        )}
                      </div>
                      <div className={`absolute -inset-2 w-full h-full -z-10`}>
                        <AnimatePresence
                          mode="sync"
                          // key={background?.images?.fallback?.src}
                        >
                          <motion.div
                            className="relative w-full h-full overflow-hidden inset-2 "
                            initial="hidden"
                            animate="visible"
                            exit="hidden"
                            key={item.background_image.localFile.url}
                            variants={ImageVariants}
                            transition={{
                              duration: 1,
                              easings: "anticipate",
                              ease: "anticipate",
                            }}
                          >
                            <div
                              className="absolute w-full h-full overflow-hidden z-10 pl-5 -left-5 pointer-events-none bg-gradient-to-r from-black via-black/70 to-transparent"
                              data-
                            />

                            <div
                              className={`absolute w-full top-0 left-2 h-full overflow-hidden`}
                            >
                              <AnimatePresence mode="sync">
                                {!background ? (
                                  <GatsbyImage
                                    key={item.background_image.localFile.url}
                                    image={
                                      item.background_image.localFile
                                        .childImageSharp.gatsbyImageData
                                    }
                                    alt={item.title}
                                    className={`w-[110%] h-[110%] object-cover z-0`}
                                  />
                                ) : (
                                  <motion.div
                                    className={`w-full h-full z-0`}
                                    initial={{
                                      clipPath:
                                        "polygon(20% 0%,80% 0%,80% 0%,20% 0%)",
                                      scale: 0.95,
                                      y: 50,
                                    }}
                                    animate={{
                                      clipPath:
                                        "polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)",
                                      scale: 1,
                                      y: 0,
                                    }}
                                    key={background?.images?.fallback?.src}
                                  >
                                    <GatsbyImage
                                      key={background?.images?.fallback?.src}
                                      image={background}
                                      alt={item.title}
                                      className={`w-[110%] h-[110%] object-cover z-0`}
                                    />
                                  </motion.div>
                                )}
                              </AnimatePresence>
                            </div>
                          </motion.div>
                        </AnimatePresence>
                      </div>
                    </motion.div>
                  </NavigationMenuContent>
                </NavigationMenuItem>
              );
            })}
          </div>
          <div className="inline-block w-1/6">
            <InternalButton
              buttonText={buttonText}
              buttonBgColor={buttonBgColor}
              buttonTextColor={buttonTextColor}
              buttonLink={buttonLink}
              uppercase={buttonUppercase}
              className="py-1 px-4 text-sm font-thin shadow-md hover:shadow-lg"
            />
          </div>
        </div>
      </NavigationMenuList>
    </NavigationMenu>
  );
}

const ListItem = React.forwardRef<
  React.ElementRef<"a">,
  React.ComponentPropsWithoutRef<"a">
>(({ className, title, children, href, ...props }, ref: any) => {
  return (
    <li>
      <NavigationMenuLink asChild>
        <Link
          ref={ref}
          to={href || "/"}
          className={cn(
            "block select-none space-y-1 rounded-md p-3 leading-none no-underline outline-none transition-colors hover:text-AECOM_EarthGreen focus:bg-accent focus:text-accent-foreground hover:animate-in",
            className
          )}
          {...props}
        >
          <div className="text-sm font-medium leading-none">{title}</div>
          <p className="line-clamp-2 text-sm leading-snug text-muted-foreground">
            {children}
          </p>
        </Link>
      </NavigationMenuLink>
    </li>
  );
});
ListItem.displayName = "ListItem";
