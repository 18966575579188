import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { consoleLog, isSSR, pushToDataLayer } from "../utils/Helpers";
import axios from "axios";
import flow from "lodash/fp/flow";
import groupBy from "lodash/fp/groupBy";
import sortBy from "lodash/fp/sortBy";
let LanguageCodes = require("../utils/languages/languageCodes.json");
let Regions = require("../utils/languages/regions.json");
let SubRegions = require("../utils/languages/sub_regions.json");
let UI = require("../languages/ui_translations.json");
export type contactState = {
  error: string;
  loading: string;
  geo: null | any;
  languageCodes: any;
  currentlocale: string;
  pageLocales: string;
  AECOMregions: any;
  Subregions: any;
  uiTranslation: any;
  AECOMregion: null | any;
  id: null | any;
  countries: null | any;
  SiteName: null | any;
  source: any;
  AECOMContact: null | any;
  videoReady: boolean;
  aecom_meta_data: null | any;
  contacts: null | any;
  articles: null | any;
  people: null | any;
  projects: null | any;
  withoutlimits: null | any;
  wlContent: any;
  allarticles: any;
  markets: null | any;
  services: null | any;
  service: null | any;
  valueprops: any;
  pages: null | any;
  selectedservice: null | any;
  selectedmarket: null | any;
  selectedtype: null | any;
  products: null | any;
};

const initialState: contactState = {
  error: "",
  loading: "",
  geo: null,
  languageCodes: LanguageCodes,
  currentlocale: "en",
  pageLocales: "",
  AECOMregions: Regions,
  Subregions: SubRegions,
  uiTranslation: UI,
  AECOMregion: null,
  countries: null,
  id: null,
  SiteName: null,
  source: {},
  AECOMContact: null,
  videoReady: false,
  aecom_meta_data: null,
  contacts: null,
  articles: null,
  people: null,
  projects: null,
  withoutlimits: null,
  wlContent: [],
  allarticles: [],
  markets: null,
  services: null,
  service: null,
  valueprops: [],
  pages: null,
  selectedservice: null,
  selectedmarket: null,
  selectedtype: null,
  products: null,
};

export const contactSlice = createSlice({
  name: "contact",
  initialState,
  reducers: {
    setError: (state, action) => {
      state.error = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setGeo: (state, action) => {
      state.geo = action.payload;
    },
    setLanguageCodes: (state, action) => {
      state.languageCodes = action.payload;
    },
    setCurrentlocale: (state, action) => {
      state.currentlocale = action.payload;
    },
    // setPageLocales: (state, action) => {
    //   state.pageLocales = action.payload;
    // },
    setAECOMregions: (state, action) => {
      state.AECOMregions = action.payload;
    },
    setSubregions: (state, action) => {
      state.Subregions = action.payload;
    },
    setAECOMregion: (state, action) => {
      state.AECOMregion = action.payload;
    },
    setCountries: (state, action) => {
      state.countries = action.payload;
    },
    setId: (state, action) => {
      state.id = action.payload;
    },
    setSiteName: (state, action) => {
      state.SiteName = action.payload;
    },
    setSource: (state, action) => {
      state.source = action.payload;
    },
    setAECOMContact: (state, action) => {
      state.AECOMContact = action.payload;
    },
    setAecomMetaData: (state, action) => {
      state.aecom_meta_data = action.payload;
    },
    setContacts: (state, action) => {
      state.contacts = action.payload;
    },
    setArticles: (state, action) => {
      state.articles = action.payload;
    },
    setPeople: (state, action) => {
      state.people = action.payload;
    },
    setProjects: (state, action) => {
      state.projects = action.payload;
    },
    setWithoutlimits: (state, action) => {
      state.withoutlimits = action.payload;
    },
    setWlContent: (state, action) => {
      state.wlContent = action.payload;
    },
    setAllarticles: (state, action) => {
      state.allarticles = action.payload;
    },
    setMarkets: (state, action) => {
      state.markets = action.payload;
    },
    setServices: (state, action) => {
      state.services = action.payload;
    },
    setService: (state, action) => {
      state.service = action.payload;
    },
    setValueprops: (state, action) => {
      state.valueprops = action.payload;
    },
    setPages: (state, action) => {
      state.pages = action.payload;
    },
    setSelectedservice: (state, action) => {
      state.selectedservice = action.payload;
    },
    setSelectedmarket: (state, action) => {
      state.selectedmarket = action.payload;
    },
    setSelectedtype: (state, action) => {
      state.selectedtype = action.payload;
    },
    setProducts: (state, action) => {
      state.products = action.payload;
    },
  },
  extraReducers: (builder: any) => {
    builder.addCase(NewSetTracking.pending, (state: any, action: any) => {
      state.loading = "pending";
    });
    builder.addCase(NewSetTracking.rejected, (state: any, action: any) => {
      state.loading = "rejected";
      state.error = action.error.message;
    });
    builder.addCase(NewSetTracking.fulfilled, (state: any, action: any) => {
      state.loading = "fulfilled";
      state.aecom_meta_data = action.payload;
    });
    builder.addCase(getLocation.pending, (state: any, action: any) => {
      state.loading = "pending";
    });
    builder.addCase(getLocation.rejected, (state: any, action: any) => {
      state.loading = "rejected";
      state.error = action.error.message;
    });
    builder.addCase(getLocation.fulfilled, (state: any, action: any) => {
      state.loading = "fulfilled";
      state.geo = action.payload;
    });
    builder.addCase(NewSetPageData.pending, (state: any, action: any) => {
      state.loading = "pending";
    });
    builder.addCase(NewSetPageData.rejected, (state: any, action: any) => {
      state.loading = "rejected";
      state.error = action.error.message;
    });
    builder.addCase(NewSetPageData.fulfilled, (state: any, action: any) => {
      state.loading = "fulfilled";
    });
    builder.addCase(getWL.pending, (state: any, action: any) => {
      state.loading = "pending";
    });
    builder.addCase(getWL.rejected, (state: any, action: any) => {
      state.loading = "rejected";
      state.error = action.error.message;
    });
    builder.addCase(getWL.fulfilled, (state: any, action: any) => {
      state.loading = "fulfilled";
    });
    builder.addCase(setPageLocales.pending, (state: any, action: any) => {
      state.loading = "pending";
    });
    builder.addCase(setPageLocales.rejected, (state: any, action: any) => {
      state.loading = "rejected";
      state.error = action.error.message;
    });
    builder.addCase(setPageLocales.fulfilled, (state: any, action: any) => {
      state.loading = "fulfilled";
      state.pageLocales = action.payload;
    });
  },
});

// Async Thunks

interface PageData {
  contacts: [
    {
      name: string;
      Social: string[];
      email: string;
      position: string;
      image: string;
    },
  ];

  countries: [
    {
      Name: string;
    },
  ];

  site: {
    title: string;
  };
}

interface Countries {
  title: string;
}
interface Contacts {
  name: string;
  Social: string[];
  email: string;
  position: string;
  image: string;
}

export const setPageLocales = createAsyncThunk(
  "contact/setPageLocales",
  async (data: any, { dispatch }: any) => {
    // let AllLocales = data.pagelocales.concat({ locale: data.currentlocale });

    dispatch(setCurrentlocale(data.currentlocale));

    return "en";
  }
) as any;

export const NewSetPageData = createAsyncThunk(
  "contact/NewSetPageData",
  async (pagedata: any, { dispatch }: any) => {
    dispatch(setArticles(pagedata.allStrapiArticle.articles));
    dispatch(setWithoutlimits(pagedata.allStrapiWithoutlimit.WL));
    // dispatch({ type: "PROJECTS", payload: pagedata.allStrapiProject.projects  });

    let AllArticles = [] as any;
    pagedata.allStrapiArticle.articles.forEach((value: any, key: any) => {
      if (value.node.locale === "en") {
        !value.node.slug.includes("delete") &&
          AllArticles.push({
            type: value.node.type,
            title: value.node.title,
            image: value.node.Header_image,
            slug: value.node.slug,
            author: value.node.article_authors,
            date: value.node.createdAt,
            locale: value.node.locale,
            markets: value.node.markets,
            services: value.node.service_pillars,
            subServices: value.node.services,
            featured: value.node.featured,
          });
      }
    });
    pagedata.allStrapiWithoutlimit.WL.forEach((value: any, key: any) => {
      !value.node.slug.includes("delete") &&
        AllArticles.push({
          type: "article",
          title: value.node.title,
          image: value.node.OG_image,
          slug: value.node.slug,
          author: value.node.contacts,
          date: value.node.createdAt,
          locale: "en",
          markets: value.node.markets,
          services: value.node.service_pillars,
          subServices: value.node.services,
          featured: false,
        });
    });
    dispatch(setAllarticles(AllArticles));
    let Projects = [] as any;
    pagedata.allStrapiProject.projects.forEach((value: any, key: any) => {
      if (value.node.locale === "en") {
        !value.node.slug.includes("delete") &&
          Projects.push({
            type: "project",
            title: value.node.Project_name,
            image: value.node.Project_image,
            slug: value.node.slug,
            author: value.node.contacts,
            date: value.node.createdAt,
            country:
              value.node.country === null
                ? { Name: "Global" }
                : value.node.country,
            locale: value.node.locale,
            markets: value.node.markets,
            services: value.node.service_pillars,
            subServices: value.node.services,
          });
      }
    });
    dispatch(setProjects(Projects));

    let allContacts = [] as any;
    pagedata.allStrapiContact.Contacts.forEach((value: any, key: any) => {
      allContacts.push({
        type: "people",
        slug: value.node.slug,
        name: value.node.name,
        Social: value.node.Social,
        email: value.node.email,
        title: value.node.name,
        position: value.node.position,
        image: value.node.image,
        submessage: "",
      });
    });
    dispatch(setContacts(allContacts));

    let TEMP = [];

    for (let object of pagedata.allStrapiMarket.markets) {
      !object.node.slug.includes("delete") && TEMP.push(object.node);
    }

    let Market_locale = flow(groupBy("locale"))(sortBy("slug")(TEMP));

    dispatch(setMarkets(Market_locale));
    TEMP = [];
    for (let object of pagedata.allStrapiServicePillar.servicespillars) {
      !object.node.slug.includes("delete") && TEMP.push(object.node);
    }
    let ServicePillar_locale = flow(groupBy("locale"))(
      sortBy("menu_order")(TEMP)
    );
    dispatch(setServices(ServicePillar_locale));
    TEMP = [];
    for (let object of pagedata.allStrapiService.services) {
      !object.node.slug.includes("delete") && TEMP.push(object.node);
    }

    let Service_locale = flow(groupBy("locale"))(TEMP);
    TEMP = [];
    for (let object of pagedata.allStrapiValueProposition.value) {
      !object.node.slug.includes("delete") && TEMP.push(object.node);
    }
    let valueProp_locale = flow(groupBy("locale"))(TEMP);

    // find items in pages that should be in about menu
    for (let aboutMenu of pagedata.strapiHomepage.About_pages) {
      for (let inMenu of pagedata.allStrapiPage.pages) {
        if (aboutMenu.slug === inMenu.node.slug) inMenu.node.menu = "about";
      }
    }
    // we can add in other page menus if we need them here.
    TEMP = [];
    for (let object of pagedata.allStrapiPage.pages) {
      !object.node.slug.includes("delete") && TEMP.push(object.node);
    }
    let AboutMenuTemp = [];
    for (let object of TEMP) {
      for (let menu of pagedata.strapiHomepage.About_pages) {
        object.slug === menu.slug && AboutMenuTemp.push(object);
      }
    }

    let pages_locale = flow(groupBy("locale"))(AboutMenuTemp);

    dispatch(setPages(pages_locale));

    //really convoluted way to get services into an object under locale and service pillar
    //needs work but currently working
    let servicesSorted = {};
    for (let lang in Service_locale) {
      let servicesTemp = { [lang]: {} } as any;
      for (let pillar of ServicePillar_locale[lang]) {
        let Pillar = [];
        for (let service of Service_locale[lang]) {
          for (let servicepillar of service.service_pillars) {
            if (servicepillar.slug === pillar.slug) {
              Pillar.push(service);
            }
          }
        }
        servicesTemp[lang][pillar.slug] = Pillar;
        servicesSorted = { ...servicesSorted, ...servicesTemp };
      }
      dispatch(setService(servicesSorted));
    }

    TEMP = [];
    for (let object of pagedata.allStrapiProduct.products) {
      !object.node.slug.includes("delete") &&
        !object.node.beta &&
        TEMP.push(object.node);
    }
    let products_locale = flow(groupBy("locale"))(TEMP);
    dispatch(setProducts(products_locale));
  }
) as any;

export const NewSetTracking = createAsyncThunk(
  "contact/NewSetTracking",
  async ({ data, markets, services, campaigns, products }: any, thunkAPI) => {
    // console.log("data", data);

    //reset the object to send to GTM so anything missing is not included
    let MetaData = {
      aecom_meta_data: {
        campaigns: undefined,
        markets: undefined,
        services: undefined,
        products: undefined,
        all: [],
      },
    } as any;
    //check if page level market is set
    if (markets !== null && markets !== undefined) {
      //check if page level market is array
      if (Array.isArray(markets)) {
        //if it is push titles to an array
        let Markets = [];
        for (let market of markets) {
          Markets.push(market.short_title);
        }
        // set GTM object with values
        MetaData.aecom_meta_data.markets = Markets;
        MetaData.aecom_meta_data.all.push(Markets);
      } else {
        //if object is not an array just use the basic object - assuming this is just an title not an object
        MetaData.aecom_meta_data.markets = [markets];
        MetaData.aecom_meta_data.all.push(markets);
      }
      //if original metadata has not been set as 'null' for the purpose of graphql then use the original metadata
    } else if (data.market !== "null") {
      MetaData.aecom_meta_data.markets = [data.market];
      MetaData.aecom_meta_data.all.push(data.market);
    }

    //check if page level service is set
    if (services !== null && services !== undefined) {
      //check if page level service is array
      if (Array.isArray(services)) {
        //if it is push titles to an array
        let Services = [];
        for (let service of services) {
          Services.push(service.short_title);
        }
        // set GTM object with values
        MetaData.aecom_meta_data.services = Services;
        MetaData.aecom_meta_data.all.push(Services);
      } else {
        //if object is not an array just use the basic object - assuming this is just an title not an object
        MetaData.aecom_meta_data.services = [services];
        MetaData.aecom_meta_data.all.push(services);
      }
    } else if (data.service !== "null") {
      //if original metadata has not been set as 'null' for the purpose of graphql then use the original metadata
      MetaData.aecom_meta_data.services = [data.service];
      MetaData.aecom_meta_data.all.push(data.service);
    }

    if (campaigns !== null && campaigns !== undefined) {
      if (Array.isArray(campaigns)) {
        let Campaigns = [];
        for (let campaign of campaigns) {
          Campaigns.push(campaign);
        }
        MetaData.aecom_meta_data.campaigns = Campaigns;
        MetaData.aecom_meta_data.all = Campaigns;
        MetaData.aecom_meta_data.all.push(Campaigns);
      } else {
        MetaData.aecom_meta_data.campaigns = [campaigns];
        MetaData.aecom_meta_data.all.push(campaigns);
      }
    } else if (data.campaign !== "null") {
      MetaData.aecom_meta_data.campaigns = [data.campaign];
      MetaData.aecom_meta_data.all.push(data.campaign);
    }

    if (products !== null && products !== undefined) {
      if (Array.isArray(products)) {
        let Products = [];
        for (let product of products) {
          Products.push(product);
        }
        MetaData.aecom_meta_data.products = Products;
        MetaData.aecom_meta_data.all = Products;
        MetaData.aecom_meta_data.all.push(Products);
      } else {
        MetaData.aecom_meta_data.products = [products];
        MetaData.aecom_meta_data.all.push(products);
      }
    } else if (data.product !== "null") {
      MetaData.aecom_meta_data.products = [data.product];
      MetaData.aecom_meta_data.all.push(data.product);
    }

    return MetaData.aecom_meta_data;
  }
) as any;

export const getLocation = createAsyncThunk(
  "contact/getLocation",
  async (_, { getState, dispatch }: any) => {
    const setAECOMregionHandler = async (region: string) => {
      consoleLog("setAECOMregionHandler", region);

      try {
        await dispatch(setAECOMregion(region));
      } catch (error) {
        console.log("error", error);
      }
    };
    if (!isSSR) {
      if (localStorage.getItem("DigitalAECOM") === null) {
        // dispatch({ type: "GEO_LOADING" });
        return axios
          .get(
            `https://api.ipstack.com/check?access_key=ed812e03f23c3e8cef4c2a73b93992a7&hostname=1`
          )
          .then((response) => {
            if (response.data.success !== false) {
              consoleLog("ipstack response", response.data);
              //set the aecom region
              let Regions = getState().contact.AECOMregions;
              let countryName = response.data.country_name;
              Regions.Region.forEach((value: any, key: any) => {
                if (value.data !== undefined) {
                  value.data.forEach((country: any, key: any) => {
                    consoleLog(country);
                    if (country.name === countryName) {
                      consoleLog("country", country);
                      setAECOMregionHandler(value.region);
                    }
                  });
                }
              });

              return response.data;
            }
          })
          .catch((error) => {
            // dispatch({ type: "GEO_ERROR", payload: error });
            return "there was a problem loading the data - " + error;
          });
      } else {
        //set the aecom region
        let Regions = getState().contact.AECOMregions;
        let countryName = JSON.parse(
          localStorage.getItem("DigitalAECOM") || "{}"
        ).country_name;
        Regions.Region.forEach((value: any, key: any) => {
          value.data.forEach((country: any, key: any) => {
            if (country.name === countryName)
              dispatch(setAECOMregion(value.region));
          });
        });
        const data = JSON.parse(localStorage.getItem("DigitalAECOM") || "{}");
        return {
          ...data,
        };
      }
    }
  }
) as any;

export const getWL = createAsyncThunk(
  "contact/getWL",
  async (id: any, { getState, dispatch }: any) => {
    const WLEndPoint =
      "https://www.aecom.com/without-limits/wp-json/wp/v2/article?";

    let getdata = true;
    if (getState().contact.wlContent.length > 0) {
      getState().contact.wlContent.forEach((value: any, key: any) => {
        if (value.id === id) {
          getdata = false;
        }
      });
    }
    if (getdata) {
      return axios
        .get(`${WLEndPoint}include=${id}&_embed`)
        .then((response) => {
          consoleLog("getWL", response);
          dispatch(setWlContent(response.data));
        })
        .catch((error) => {
          consoleLog("getWL error", error);
          dispatch(setError(error));
        });
    }
  }
) as any;

// Selectors

export const selectError = (state: any) => state.contact.error;
export const selectLoading = (state: any) => state.contact.loading;
export const selectGeo = (state: any) => state.contact.geo;
export const selectLanguageCodes = (state: any) => state.contact.languageCodes;
export const selectCurrentlocale = (state: any) => state.contact.currentlocale;
export const selectPageLocales = (state: any) => state.contact.pageLocales;
export const selectAECOMregions = (state: any) => state.contact.AECOMregions;
export const selectSubregions = (state: any) => state.contact.Subregions;
export const selectAECOMregion = (state: any) => state.contact.AECOMregion;
export const selectInApp = (state: any) => state.contact.inApp;
export const selectId = (state: any) => state.contact.id;
export const selectSiteName = (state: any) => state.contact.SiteName;
export const selectSource = (state: any) => state.contact.source;
export const selectAECOMContact = (state: any) => state.contact.AECOMContact;
export const selectVideoReady = (state: any) => state.contact.videoReady;
export const selectAecomMetaData = (state: any) =>
  state.contact.aecom_meta_data;
export const selectPopups = (state: any) => state.contact.popups;
export const selectPopupsShown = (state: any) => state.contact.popupsShown;
export const selectContacts = (state: any) => state.contact.contacts;
export const selectArticles = (state: any) => state.contact.articles;
export const selectPeople = (state: any) => state.contact.people;
export const selectProjects = (state: any) => state.contact.projects;
export const selectWithoutlimits = (state: any) => state.contact.withoutlimits;
export const selectWlContent = (state: any) => state.contact.wlContent;
export const selectAllarticles = (state: any) => state.contact.allarticles;
export const selectMarkets = (state: any) => state.contact.markets;
export const selectServices = (state: any) => state.contact.services;
export const selectService = (state: any) => state.contact.service;
export const selectValueprops = (state: any) => state.contact.valueprops;
export const selectPages = (state: any) => state.contact.pages;
export const selectSelectedservice = (state: any) =>
  state.contact.selectedservice;
export const selectSelectedmarket = (state: any) =>
  state.contact.selectedmarket;
export const selectSelectedtype = (state: any) => state.contact.selectedtype;
export const selectProducts = (state: any) => state.contact.products;
export const selectUiTranslation = (state: any) => state.contact.uiTranslation;
//  Actions
export const {
  setError,
  setLoading,
  setGeo,
  setLanguageCodes,
  setCurrentlocale,
  setAECOMregions,
  setSubregions,
  setAECOMregion,
  setCountries,
  setId,
  setSiteName,
  setSource,
  setAECOMContact,
  setAecomMetaData,
  setContacts,
  setArticles,
  setPeople,
  setProjects,
  setWithoutlimits,
  setWlContent,
  setAllarticles,
  setMarkets,
  setServices,
  setService,
  setValueprops,
  setPages,
  setSelectedservice,
  setSelectedmarket,
  setSelectedtype,
  setProducts,
} = contactSlice.actions;

export default contactSlice.reducer;
