import { useStaticQuery, graphql } from "gatsby";

export interface MenuItem {
  title: string;
  slug: string;
}
export interface HeaderItems {
  title: string;
  slug: string;
  description: {
    type: string;
    children: {
      text: string;
      type: string;
    }[];
  };
  background_image: {
    caption: string;
    localFile: {
      childImageSharp: {
        gatsbyImageData: any;
      };
    };
    url: string;
  };
  avatar: {
    localFile: {
      childImageSharp: {
        gatsbyImageData: any;
      };
    };
    url: string;
  };
  MenuItem: MenuItem[];
}

export const useHeaderData = () => {
  const { strapiHeader } = useStaticQuery(graphql`
    query HeaderQuery {
      strapiHeader {
        MenuSection {
          title
          slug
          description {
            type
            children {
              text
              type
            }
          }
          button {
            buttonText
            buttonLink
            uppercase
            type
            className
            buttonBgColor
            buttonTextColor
            strapi_component
          }
          background_image {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  layout: FULL_WIDTH
                  quality: 90
                  placeholder: BLURRED
                )
              }
            }
            url
          }
          MenuItem {
            slug
            title
            description {
              type
              level
              children {
                bold
                text
                type
              }
            }
            background_image {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    layout: FULL_WIDTH
                    quality: 90
                    placeholder: BLURRED
                  )
                }
              }
              url
            }
          }
        }
      }
    }
  `);
  // console.log("site", site);
  return strapiHeader.MenuSection as HeaderItems;
};
