import axios from "axios";
import { type ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";

export const convertToTimeString = (time: number) => {
  const minutes = Math.floor(time / 60);
  const seconds = time - minutes * 60;

  const formattedTime = `${minutes}m${seconds}s`;
  return formattedTime;
};

interface WindowDataLayer {
  push: (data: any) => void;
}
interface Clarity {
  consent: (data: boolean) => void;
  [key: string]: any;
}

declare global {
  interface Window {
    dataLayer: WindowDataLayer;
    _wq: Clarity;
  }
}
export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}
export const developement = process.env.NODE_ENV === "development";
export const isSSR = typeof window === "undefined";
export const consoleLog = (title: string, data?: any) => {
  if (developement) {
    console.log(
      `%c ${title} is ${typeof data}`,
      "color: #499167; font-size: 16px; font-weight: bold; text-transform: uppercase;",
      "\n",
      "\n",
      data,
      "\n",
      "\n"
    );
    if (Array.isArray(data)) {
      if (data.length < 10) {
        console.table(title, data);
      }
    }
    if (typeof data === "object") {
      if (data !== null) {
        console.log(
          `%c 
          \n
          ${title} Object keys are:
          `,
          "color: #a072b4; font-size: 12px; font-weight: bold;"
        );
        console.log(
          `%c 
          ${Object.keys(data)}
          `,
          "color: #d49dec; font-size: 12px; font-weight: bold;"
        );
      }
    }
    if (data instanceof Error) {
      console.error(data);
    }
  }
};

// accept an object of data to push to the dataLayer
export const pushToDataLayer = (data: any) => {
  if (developement) {
    consoleLog("pushToDataLayer data: ", data);
  }
  if (!isSSR) {
    if (
      window.dataLayer &&
      Array.isArray(window.dataLayer) &&
      window.dataLayer !== undefined
    ) {
      const dataLayer = window.dataLayer;
      dataLayer.push(data);
    } else {
      console.error("window.dataLayer not found", data);
    }
  } else {
    console.error("window not found");
  }
};
// Salesforce Marketing Cloud

export function getAccountName(ACID: string) {
  consoleLog("getAccountName ACID", ACID);
  axios({
    method: "get",
    url: `https://sf.api.withoutlimit.net/sc/sobjects/Account/${ACID}?fields=Name`,
    headers: {
      "Content-Type": "application/json",
    },
  }).then((response) => {
    consoleLog("Account Name", response);

    if (response.data.Name !== undefined) {
      !isSSR && localStorage.setItem("ACCOUNTID", JSON.stringify(ACID));
      !isSSR &&
        pushToDataLayer({
          event: "setAccountID",
          accountID: ACID,
          accountName: response.data.Name,
        });
    }
  });
}

export const getRandomColour = () => {
  const colours = [
    "AECOM_LimeGreen",
    "AECOM_OceanTeal",
    "AECOM_EarthGreen",
  ];
  const randomIndex = Math.floor(Math.random() * colours.length);
  return colours[randomIndex];
};
