import { consoleLog } from "@utils/Helpers";
import React, { useState, useEffect } from "react";

interface Props {
  disabled?: boolean;
  checked: boolean;
  setChecked: (checked: boolean) => void;
}

const ToggleAll = ({ disabled, checked, setChecked }: Props) => {
  consoleLog("Toggle", {
    disabled,
    checked,
  });

  let toggleRef = React.createRef() as any;

  return (
    <div
      className={
        disabled
          ? "opacity-60  flex items-center justify-start mb-12"
          : "flex items-center justify-start mb-12"
      }
    >
      <label className="md:flex items-center cursor-pointer">
        <div className="relative">
          <input
            onChange={() => setChecked(!checked)}
            type="checkbox"
            ref={toggleRef}
            className="sr-only"
            checked={checked}
            disabled={disabled}
          />

          <div className="block border-white border-2 w-14 h-8 rounded-full"></div>

          <div className="dot absolute left-1 top-1 bg-white w-6 h-6 rounded-full transition"></div>
        </div>
        <div className="md:ml-3 md:mt-0 mt-4 ml-0 text-white font-medium ">
          {checked ? "All allowed" : "All denied"}
        </div>
      </label>
    </div>
  );
};

export default ToggleAll;
