import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { LazyMotion, domAnimation, m } from "framer-motion";
import {
  selectManageCookies,
  setCustomConsent,
  setManageCookies,
} from "../../../slices/consentSlice";
import { consoleLog, isSSR } from "../../../utils/Helpers";
import Cookies from "js-cookie";
import Toggle from "./Toggle";
import { setConsent, setDefaults } from "./ConsentHelpers";
import Balancer from "react-wrap-balancer";
import ToggleAll from "./ToggleAll";

const Consent = () => {
  const ManageCookies = useSelector(selectManageCookies);
  const dispatch = useDispatch();
  const [isconsentOpen, toggleOpen] = useState("consent");
  const [hasConsnet, setHasConstent] = useState(true);
  const [consentOptions, setConsentOptions] = useState({
    required: true,
    general: true,
    content: true,
    security: true,
  }) as [
    {
      required: boolean;
      general: boolean;
      content: boolean;
      security: boolean;
    },
    Dispatch<
      SetStateAction<{
        required: boolean;
        general: boolean;
        content: boolean;
        security: boolean;
      }>
    >,
  ];
  const [toggledAll, setToggledAll] = useState(undefined as any);

  const scrollRef = useRef() as any;

  consoleLog("Consent Banner", {
    consentOptions: Object.keys(consentOptions).slice(1),
    toggledAll,
    isAllToggled: Object.keys(consentOptions)
      .slice(1)
      // @ts-ignore
      .every((key) => consentOptions[key] === true),
    hasConsnet,
  });

  const handleOverflow = () => {
    if (isconsentOpen !== "manageOpen") {
      document.body.style.overflow = "auto";
    } else {
      document.body.style.overflow = "hidden";
    }
  };

  useEffect(() => {
    setConsentOptions({
      required: true,
      general: toggledAll,
      content: toggledAll,
      security: toggledAll,
    });
  }, [toggledAll]);

  useEffect(() => {
    if (!isSSR) {
      let cookieValue = Cookies.get("DigitalAECOMConsent");
      if (!ManageCookies.open) {
        if (localStorage.getItem("DigitalAECOMConsent") !== null) {
          setConsent();
          //@ts-ignore
          cookieValue && setHasConstent(cookieValue);
          dispatch(setCustomConsent());
          setConsentOptions(
            JSON.parse(localStorage.getItem("DigitalAECOMConsent") || "{}")
          );
          const isAllToggled = Object.keys(
            JSON.parse(localStorage.getItem("DigitalAECOMConsent") || "{}")
          )
            .slice(1)
            // @ts-ignore
            .every((key) => consentOptions[key] === true);

          if (isAllToggled) {
            setToggledAll(true);
          } else {
            setToggledAll(false);
          }
        } else {
          setHasConstent(false);
          setDefaults();
        }
      }
    }
  }, [hasConsnet]);

  useEffect(() => {
    if (ManageCookies.open) {
      setHasConstent(false);
      toggleOpen("manageOpen");
    }
  }, [ManageCookies]);

  const save = () => {
    toggleOpen("consentClosed");
    //set consent cookie
    Cookies.set("DigitalAECOMConsent", "true", {
      expires: 365,
      secure: true,
      sameSite: "strict",
    });
    //set consent options
    localStorage.setItem("DigitalAECOMConsent", JSON.stringify(consentOptions));
    setConsent();
    dispatch(setCustomConsent());
    dispatch(setManageCookies(false));
  };

  const cancel = () => {
    toggleOpen("consentOpen");
    dispatch(setManageCookies(false));
  };

  const Consentvariants = {
    consentClosed: {
      y: "200%",
      transition: {
        y: { stiffness: 1000 },
      },
    },
    consentOpen: {
      y: "0",
      transition: {
        y: { stiffness: 1000 },
      },
    },
    manageOpen: {
      y: "0",
      height: "100vh",
      transition: {
        y: { stiffness: 1000 },
      },
    },
  };

  const prefvariants = {
    manageOpen: {
      opacity: 0,
      transitionEnd: {
        display: "none",
      },
      transition: {
        opacity: { duration: 0.2, delay: 0.1, stiffness: 1000 },
      },
    },
    manageClosed: {
      opacity: 1,
      transitionEnd: {
        display: "block",
      },
      transition: {
        opacity: { delay: 0.2, stiffness: 1000 },
      },
    },
    consentOpen: {
      opacity: 1,
      transitionEnd: {
        display: "block",
      },
      transition: {
        opacity: { stiffness: 1000 },
      },
    },
  };

  const managevariants = {
    manageOpen: {
      opacity: 1,
      transition: {
        opacity: { duration: 0.4, stiffness: 1000 },
      },
      transitionEnd: {
        display: "block",
      },
    },
    consentOpen: {
      opacity: 0,
      transition: {
        opacity: { duration: 0.2, stiffness: 1000 },
      },
      transitionEnd: {
        display: "none",
      },
    },
  };

  return (
    <LazyMotion features={domAnimation}>
      {!hasConsnet ? (
        <m.div
          variants={Consentvariants}
          ref={scrollRef}
          animate={isconsentOpen}
          className="overflow-auto shadow-black z-50 fixed bottom-0 h-auto mx-auto bg-AECOM_EarthGreen w-full md:px-24 px-8 py-10 justify-center text-white text-lg"
        >
          <m.div
            variants={prefvariants}
            animate={isconsentOpen}
            className="md:w-3/4 w-11/12"
          >
            <p className="font-bold mb-2 text-3xl ">
              <Balancer>Are you happy to accept cookies?</Balancer>
            </p>
            <p className="font-semibold text-xl">
              <Balancer>
                Our website uses cookies and similar technologies to provide you
                with the best possible personalised experience.
              </Balancer>
            </p>
            <p className="text-base mt-4">
              <Balancer>
                Some are necessary to help our website work properly, some are
                optional but enhance your experience - helping you find the
                things you are looking for faster. We may store cookies and
                other similar technologies to access personal data, including
                browsing data and unique identifiers. We only use this
                information about you, your devices and your online interactions
                with us to provide, analyse and improve our services.
              </Balancer>
            </p>

            <div className="flex md:w-1/2 w-full md:text-left text-center gap-10 xl:gap-14">
              <div className="xl:w-1/2 w-1/2" onClick={() => save()}>
                <div className="md:text-xl text-base z-50 w-auto cursor-pointer py-2 md:px-6 px-2 border-2 border-white bg-white text-AECOM_EarthGreen rounded-3xl font-semibold transition ease-out duration-300 transform hover:opacity-80 hover:scale-110 mt-6 origin-left">
                  <Balancer>Yes, I'm happy</Balancer>
                </div>
              </div>
              <div
                className="xl:w-1/2  w-1/2  mt-auto"
                onClick={() => toggleOpen("manageOpen")}
              >
                <div className="md:text-xl text-base z-50 w-auto cursor-pointer py-2 md:px-6 px-2 border-2 border-white  bg-transparent text-white rounded-3xl font-semibold transition ease-out duration-300 transform hover:opacity-80 hover:scale-110 mt-6 origin-left">
                  <Balancer>Manage cookies</Balancer>
                </div>
              </div>
            </div>
          </m.div>
          <m.div
            variants={managevariants}
            animate={isconsentOpen}
            className="opacity-0 hidden"
          >
            <p className="w-3/4 font-bold md:text-3xl text-2xl">
              We use cookies and similar technologies for the following
              purposes:
            </p>

            <div className="flex mt-10 gap-5">
              <div className="md:w-1/2 w-3/4 mr-4">
                <p className="font-bold text-lg">
                  To ensure essential website functions work properly
                </p>
                <p className="text-sm mt-2">
                  We use these essential cookies to ensure that our digital
                  services work correctly and meet your expectations.{" "}
                  <span className="font-bold">They cannot be switched off</span>
                  . You can find out more in our{" "}
                  <a
                    className="underline underline-offset-1"
                    href="https://aecom.com/privacy-policy/"
                    rel="noreferrer"
                    target="_blank"
                  >
                    privacy policy and cookie policy.
                  </a>
                </p>
              </div>
              <div className="w-1/4 xl:w-1/3">
                <Toggle
                  disabled={true}
                  consentOptions={consentOptions}
                  consentName="required"
                  setConsentOptions={setConsentOptions}
                />
              </div>
            </div>
            <div className="flex mt-10 gap-5">
              <div className="md:w-1/2 w-3/4 mr-4">
                <p className="font-bold text-lg">
                  To store and/or access information on a device
                </p>
                <p className="text-sm mt-2">
                  We use these cookies, device identifiers, or other information
                  which can be stored or accessed on your device for the
                  purposes presented to you and to remember choices you make to
                  give you better functionality.{" "}
                  <a
                    className="underline underline-offset-1"
                    href="https://aecom.com/privacy-policy/"
                    rel="noreferrer"
                    target="_blank"
                  >
                    For a full list see our privacy policy and cookie policy.
                  </a>
                </p>
              </div>
              <div className="w-1/4 xl:w-1/3">
                <Toggle
                  consentOptions={consentOptions}
                  consentName="general"
                  setConsentOptions={setConsentOptions}
                />
              </div>
            </div>
            <div className="flex mt-10 gap-5">
              <div className="md:w-1/2 w-3/4 mr-4">
                <p className="font-bold text-lg">
                  To provide personalised content, content measurement, audience
                  insights and product development
                </p>
                <p className="text-sm mt-2">
                  These cookies allow content to be personalised based on a
                  profile. Data added to better personalise content. Content
                  performance measured. Allow insights about audiences who saw
                  the content to be derived. Let data be used to build or
                  improve user experience, systems and software.{" "}
                  <a
                    className="underline underline-offset-1"
                    href="https://aecom.com/privacy-policy/"
                    rel="noreferrer"
                    target="_blank"
                  >
                    For a full list see our privacy policy and cookie policy.
                  </a>
                </p>
              </div>
              <div className="w-1/4 xl:w-1/3">
                <Toggle
                  consentOptions={consentOptions}
                  consentName="content"
                  setConsentOptions={setConsentOptions}
                />
              </div>
            </div>
            <div className="flex mt-10 gap-5">
              <div className="md:w-1/2 w-3/4 mr-4">
                <p className="font-bold text-lg">For your and our security </p>
                <p className="text-sm mt-2">
                  We use these cookies to prevent malicious, fraudulent,
                  invalid, or illegal activity, ensure data are securely
                  transmitted and help debug our systems. They may include some
                  basic personal information such as device characteristics and
                  geolocation. This information may be analysed and stored by us
                  but will not be used for any other purpose.{" "}
                </p>
              </div>
              <div className="w-1/4 xl:w-1/3">
                <Toggle
                  consentOptions={consentOptions}
                  consentName="security"
                  setConsentOptions={setConsentOptions}
                />
              </div>
            </div>
            <div className="flex mt-10 gap-5 justify-start w-full">
              <div className="md:w-1/2 w-3/4 mr-4"></div>
              <div className="w-1/4 xl:w-1/3">
                <ToggleAll
                  disabled={false}
                  checked={toggledAll}
                  setChecked={setToggledAll}
                />
              </div>
            </div>

            <div className="flex md:w-1/2 w-full md:text-left text-center h-full">
              <div
                className="w-1/2 md:mr-10 mr-4  h-full"
                onClick={() => save()}
              >
                <div className="md:text-xl text-base z-50 w-auto cursor-pointer mr-auto py-2 md:px-6 px-3 border-2 border-white  bg-white text-AECOM_EarthGreen rounded-3xl font-semibold transition ease-out duration-300 transform hover:opacity-80 hover:scale-105 mt-6 origin-left">
                  Save and continue
                </div>
              </div>
              <div className="w-1/2 mt-auto  h-full" onClick={() => cancel()}>
                <div className="md:text-xl text-base z-50 w-auto cursor-pointer mr-auto py-2 md:px-6 px-3   text-white rounded-3xl font-semibold transition ease-out duration-300 transform hover:opacity-80 hover:scale-105 mt-6 origin-left">
                  Cancel
                </div>
              </div>
            </div>
          </m.div>
        </m.div>
      ) : null}
    </LazyMotion>
  );
};

export default Consent;
